import { TypographyOptions } from '@material-ui/core/styles/createTypography';
import rem from '#app/themes/MuiFifthWheelThemeProvider/rem';
import './style.css';

export const interFontFamily: string = `'Inter', sans-serif`; // use this in production
// export const interFontFamily: string = `'Inter', cursive, sans-serif`; // use this to identify failed fall-back
// export const interFontFamily: string = `cursive`; // use this to identify the font

/**
 * Based on:
 * https://www.figma.com/file/jRli21rb4wQU3XCsZrNnfx/MUI-5th-Wheel?node-id=6605%3A52863
 */
export const typography: TypographyOptions = {
  fontFamily: interFontFamily,
  fontWeightBold: 600,
  fontWeightMedium: 500,

  body1: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: rem(16),
    lineHeight: '150%',
    fontFeatureSettings: `'cv02' on, 'cv03' on, 'cv04' on, 'cv05' on, 'cv09' on, 'cv11' on;`
  },
  body2: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: rem(14),
    lineHeight: '143%',
    fontFeatureSettings: `'cv02' on, 'cv03' on, 'cv04' on, 'cv05' on, 'cv09' on, 'cv11' on;`
  },
  subtitle1: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: rem(16),
    lineHeight: '150%',
    fontFeatureSettings: `'cv02' on, 'cv03' on, 'cv04' on, 'cv05' on, 'cv09' on, 'cv11' on;`
  },
  subtitle2: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: rem(14),
    lineHeight: '143%',
    fontFeatureSettings: `'cv02' on, 'cv03' on, 'cv04' on, 'cv05' on, 'cv09' on, 'cv11' on;`
  },
  caption: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: rem(12),
    lineHeight: '133%',
    fontFeatureSettings: `'cv02' on, 'cv03' on, 'cv04' on, 'cv05' on, 'cv09' on, 'cv11' on;`
  },
  overline: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: rem(12),
    lineHeight: '266%',
    letterSpacing: '1px',
    textTransform: 'uppercase'
  },
  h1: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: rem(32),
    lineHeight: '125%',
    fontFeatureSettings: `'cv02' on, 'cv03' on, 'cv04' on, 'cv05' on, 'cv09' on, 'cv11' on;`
  },
  h2: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: rem(28),
    lineHeight: '114%',
    fontFeatureSettings: `'cv02' on, 'cv03' on, 'cv04' on, 'cv05' on, 'cv09' on, 'cv11' on;`
  },
  h3: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: rem(24),
    lineHeight: '133%',
    fontFeatureSettings: `'cv02' on, 'cv03' on, 'cv04' on, 'cv05' on, 'cv09' on, 'cv11' on;`
  },
  h4: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: rem(18),
    lineHeight: '133%',
    fontFeatureSettings: `'cv02' on, 'cv03' on, 'cv04' on, 'cv05' on, 'cv09' on, 'cv11' on;`
  },
  h5: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: rem(18),
    lineHeight: '133%',
    fontFeatureSettings: `'cv02' on, 'cv03' on, 'cv04' on, 'cv05' on, 'cv09' on, 'cv11' on;`
  },
  h6: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: rem(16),
    lineHeight: '150%',
    fontFeatureSettings: `'cv02' on, 'cv03' on, 'cv04' on, 'cv05' on, 'cv09' on, 'cv11' on;`
  }
};
