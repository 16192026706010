import { Color } from '@material-ui/core';

const green: Color = {
  50: '#F0FDF4',
  100: '#DCFCE7',
  200: '#BBF7D0',
  300: '#86EFAC',
  400: '#4ADE80',
  500: '#22C55E', // success.light
  600: '#16A34A',
  700: '#15803D', // success.main
  800: '#166534', // success.dark
  900: '#14532D',
  A100: 'magenta',
  A200: 'magenta',
  A400: 'magenta',
  A700: 'magenta'
};

export default green;
