import * as FS from '@fullstory/browser';
import { init } from '@fullstory/browser';
import { AppEnv, Environment } from '#app/contexts/AppContext';
import { AuthContextResult } from '#app/contexts/AuthContext';

export interface FullStoryAnalytics {
  eventName: FullStoryEvents;
  eventProperties: { [key: string]: any };
}

export const initializeFullStory = (environment: Environment) => {
  // initialize FullStory
  init({ orgId: '14PRCC', devMode: environment.APP_ENV !== AppEnv.PROD });
};

/**
 * Set the user with FullStory.
 * @param authContext
 */
export const fullstorySetUser = (authContext: AuthContextResult) => {
  // update the FullStory identity
  const fullStoryCustomVars = {
    ...authContext.externalContext,
    // https://help.fullstory.com/hc/en-us/articles/360020828113-FS-identify-Identifying-users#displayname
    displayName: authContext.userFullName,
  } as any;
  // removing legacy group attribute
  fullStoryCustomVars.group = undefined;
  FS.identify(authContext.userKey, fullStoryCustomVars);
};

/*
 * @param eventName The event name must be in PascalCase
 * @param eventProperties A dictionary with key-value pairs, The "keys" must be in camelCase.
 */
export function publishFSCustomEvent(
  eventName: string,
  eventProperties: { [key: string]: any }
) {
  try {
    FS.event(eventName, eventProperties);
  } catch (e) {
    console.log('Failed to publish fullstory custom event', e);
  }
}

/**
 * Sanitize event properties by removing properties not valid for sending to FullStory.
 * Remove __typename.
 * @param eventProperties
 * @returns
 */
export function sanitizeEventProperties(eventProperties: {
  [key: string]: any;
}) {
  const sanitized = {} as { [key: string]: any };
  for (const key in eventProperties) {
    if (key !== '__typename') {
      sanitized[key] = eventProperties[key];
    }

    const value = eventProperties[key];
    if (value && value.constructor.name === 'Object') {
      sanitized[key] = sanitizeEventProperties(eventProperties[key]);
    }
  }

  return sanitized;
}

// NOTE: This is an anti-pattern. See LoadCentral: src/modules/load-central/fullstory-events
export enum FullStoryEvents {
  DFRateConClicked = 'DFRateConClicked',
  DFPODClicked = 'DFPODClicked',
  DFEmailPODClicked = 'DFEmailPODClicked',
  DFPaymentItemClicked = 'DFPaymentItemClicked',
  DFBonusesClicked = 'DFBonusesClicked',
  DFDeductionsClicked = 'DFDeductionsClicked',
  DFReimbursementsClicked = 'DFReimbursementsClicked',
  DFLoadItemClicked = 'DFLoadItemClicked',
  DFPaymentsTabClicked = 'DFPaymentsTabClicked',
  DFLoadsTabClicked = 'DFLoadsClicked',
  DFAdjustmentsTabClicked = 'DFAdjustmentsTabClicked',
  DFHistoryTabClicked = 'DFHistoryTabClicked',
  DFLatestSettlementPDFClicked = 'DFLatestSettlementPDFClicked',
  BCAFromCarrier = 'BCAFromCarrier',
  BCAFromCustomer = 'BCAFromCustomer',
  BCAFromDocument = 'BCAFromDocument',
  AddCarrier = 'AddCarrier',
  DocumentsMenuDownload = 'DocumentsMenuDownload',
  DocumentsMenuDelete = 'DocumentsMenuDelete',
  FacetFilterSearch = 'FacetFilterSearch',
  PaywallConfigureBillingClicked = 'ActivateSubscriptionConfigureBillingClicked',
  PaywallCompleteSetupClicked = 'TrialPeriodEndCompleteSetupClicked',
  PaywallLogoutClicked = 'ActivateSubscriptionLogoutClicked',
  PaywallGetHelpClicked = 'PaywallGetHelpClicked',
  PaywallTrialEndLogoutClicked = 'TrialPeriodEndLogoutClicked',
  PaywallTrialEndGetHelpClicked = 'TrialPeriodEndPaywallGetHelpClicked',
}
