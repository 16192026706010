import { Color } from '@material-ui/core';

const grey: Color = {
  50: '#F9FAFB',  // background.default
  100: '#F3F4F6',
  200: '#E5E7EB', // divider
  300: '#D1D5DB', // text.disabled
  400: '#9CA3AF',
  500: '#6B7280', // text.secondary
  600: '#4B5563',
  700: '#374151',
  800: '#1F2937',
  900: '#111827', // text.primary
  A100: 'magenta',
  A200: 'magenta',
  A400: 'magenta',
  A700: 'magenta'
};

export default grey;
