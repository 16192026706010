import { Color } from "@material-ui/core";

const yellow: Color = {
  50: '#FFFBEB',
  100: '#FEF3C7',
  200: '#FDE68A',
  300: '#FCD34D',
  400: '#FBBF24',
  500: '#F59E0B', // warning.light
  600: '#D97706',
  700: '#B45309', // warning.main
  800: '#92400E', // warning.dark
  900: '#78350F',
  A100: 'magenta',
  A200: 'magenta',
  A400: 'magenta',
  A700: 'magenta'
};

export default yellow;
