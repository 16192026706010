import { Color } from '@material-ui/core';

const teal: Color = {
  50: '#F3F9F9',
  100: '#E7F2F4',
  200: '#D0E5E8', // secondary.light
  300: '#B8D8DD',
  400: '#A0CBD2',
  500: '#88BEC6',
  600: '#4196A5',
  700: '#127C8E', // secondary.main
  800: '#0D5763', // secondary.dark
  900: '#093E47',
  A100: 'magenta',
  A200: 'magenta',
  A400: 'magenta',
  A700: 'magenta'
};

export default teal;
