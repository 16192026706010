import { useActor } from '@xstate/react';
import { createContext, useContext } from 'react';
import { InterpreterFrom } from 'xstate';
import { bootstrapMachine } from '#app/app/globalMachine';

const isStorybook = !!(global as any)?.IS_STORYBOOK;

export enum AppEnv {
  PROD = 'PROD',
  DEV = 'DEV',
  LOCAL = 'LOCAL',
}

export type Environment = {
  /**
   * The current environment where this application is served from
   */
  APP_ENV: AppEnv;
  /**
   *  DataDog environment
   */
  DD_ENV: string;
  /**
   * DataDog backend service name
   */
  DD_SERVICE: string;
  /**
   * DataDog service version number
   */
  DD_VERSION: string;
  /**
   * GoogleMaps API public key
   */
  GOOGLE_API_KEY: string;
  /**
   * LaunchDarkly configuration
   */
  LAUNCHDARKLY_CLIENT_ID: string;
  /**
   * PubNub channel prefix
   */
  PUBNUB_CHANNEL_PREFIX: string;
  /**
   * PubNub publish key
   */
  PUBNUB_PUBLISH_KEY: string;
  /**
   * PubNub subscribe key
   */
  PUBNUB_SUBSCRIBE_KEY: string;
  /**
   * Application version
   */
  VERSION: string;
  /**
   * Auth0 application domain
   */
  AUTH0_DOMAIN: string;
  /**
   * Auth0 client identifier
   */
  AUTH0_CLIENT_ID: string;
  /**
   * Auth0 scope API identifier
   */
  API_IDENTIFIER: string;
  /*
   * Override LaunchDarkly feature flags for testing
   */
  FEATURE_FLAG_OVERRIDES?: any;
};
/**
 * Use these default values
 */
export const DefaultEnvironmentContext: Environment = {
  APP_ENV: AppEnv.LOCAL,
  DD_ENV: 'local',
  DD_SERVICE: 'tn-app-web',
  DD_VERSION: 'unknown',
  GOOGLE_API_KEY: isStorybook
    ? process.env['GOOGLE_API_KEY'] || 'not-set'
    : 'not-set',
  LAUNCHDARKLY_CLIENT_ID: isStorybook
    ? process.env['LAUNCHDARKLY_CLIENT_ID'] || 'not-set'
    : 'not-set',
  PUBNUB_CHANNEL_PREFIX: 'localdev-shared.',
  PUBNUB_PUBLISH_KEY: 'pub-c-ecf7f9f7-30a7-4326-befe-7196b87b8efe',
  PUBNUB_SUBSCRIBE_KEY: 'sub-c-688c827d-c904-4ee8-acf4-d90e85bbf023',
  AUTH0_DOMAIN: 'login-staging.truenorthfleet.com',
  AUTH0_CLIENT_ID: 'not-set',
  API_IDENTIFIER: 'https://local.truenorthfleet.com/',
  VERSION: 'unknown',
  FEATURE_FLAG_OVERRIDES: {},
};

export type AppStateData = {
  environment?: Environment;
  error?: Error;
};

/**
 * Application context.
 */
export const AppContext = createContext({
  globalService: {} as InterpreterFrom<typeof bootstrapMachine>,
});

/**
 * Hook for accessing the Application context
 */
export const useAppContext = () => useContext(AppContext);

export const useGlobalEnvironment = () => {
  const globalService = useAppContext();
  const [state] = useActor(globalService.globalService);

  const env = state.context.environment;
  if (!env) {
    if ((global as any).IS_STORYBOOK) {
      return DefaultEnvironmentContext;
    }
    throw new Error('Global environment not configured.');
  }
  return env;
};

export let GlobalEnvironment = DefaultEnvironmentContext;

export const validateEnvironment = (env: Environment) => {
  if (!env || !env.API_IDENTIFIER) {
    throw new Error('Environment not valid.');
  }
};

export const validateGlobalEnvironment = () => {
  return validateEnvironment(GlobalEnvironment);
};

export const setGlobalEnvironment = (env: Environment) => {
  GlobalEnvironment = { ...env };
};

/**
 * Used during testing to reset global environment.
 */
export const resetGlobalEnvironment = () => {
  GlobalEnvironment = DefaultEnvironmentContext;
};
