import {
  RumEvent,
  RumEventDomainContext,
  RumFetchResourceEventDomainContext,
  datadogRum,
} from '@datadog/browser-rum';
import { Environment } from '#app/contexts/AppContext';
import { AuthContextResult } from '#app/contexts/AuthContext';
import { RetreiveGitInfo } from './gitInfo';

interface GraphQLRequest {
  operationName?: string;
  query: string;
}

export const datadogRumBeforeSend = (
  event: RumEvent,
  context: RumEventDomainContext
) => {
  // collect a RUM resource's request body
  if (event.type === 'resource' && event.resource.url.endsWith('/graphql')) {
    // send the GraphQL request body as a JSON object in the context.
    const requestBody = (context as RumFetchResourceEventDomainContext)
      .requestInit?.body;
    const parsedRequestBody: GraphQLRequest = requestBody
      ? JSON.parse(requestBody.toString())
      : {};
    const { query = '' } = parsedRequestBody;
    const operationName = parsedRequestBody.operationName ?? 'not-defined';
    const operationType =
      (parsedRequestBody.operationName && query
        ? query.substring(0, query.indexOf(' '))
        : undefined) ?? 'not-defined';
    /* Now in DD, you can reference 
        @context.operationName
        @context.operationType (query/mutation/not-defined)
        @context.requestBody
     */
    event.context = {
      ...event.context,
      requestBody: parsedRequestBody,
      operationName,
      operationType,
    };
  }
};

const DefaultEnableRum = !(global as any)?.IS_STORYBOOK;

let enableRum = DefaultEnableRum;
let initialized = false;

/**
 * Initialize DataDog integration.
 * @param environment Current environment settings
 */
export function initializeDataDog(environment: Environment) {
  enableRum = DefaultEnableRum;
  const version = RetreiveGitInfo().releaseName;

  console.log(`App version: ${version}`);

  // Disable DataDog RUM integration when running under Cypress.
  if ((window as any).Cypress || (global as any)?.IS_STORYBOOK) {
    // Disable
    enableRum = false;
    // eslint-disable-next-line no-console
    console.warn('Disabled DataDog integration');
  }

  if (enableRum) {
    // Ensure the tracing origin is relative to the requesting hostname.
    // Otherwise get a CORS violation with Auth0 and x-datadog-origin header.
    // Side-effect, ignore login-staging.truenorthfleet.com and login.truenorthfleet.com
    // Assume hostname ends with '.truenorthfleet.com'
    const hostname = window.location.host;
    const hostPart = hostname.replace('.truenorthfleet.com', '');
    const hostPartRegex = hostPart.replace(/\./g, '\\.');
    const traceRegex = new RegExp(
      `https:\\/\\/${hostPartRegex}\\.truenorthfleet\\.com`
    );

    // Initialize DataDog RUM integration
    datadogRum.init({
      applicationId: 'd79e3711-2cf1-47d4-a3eb-1fabfd25ea92',
      clientToken: 'pubf3804177c4e11f9f7751fba5b868a47e',
      site: 'us3.datadoghq.com',
      service: `app-frontend`,
      env: environment.DD_ENV,
      version,
      sampleRate: 100,
      trackInteractions: true,
      allowedTracingOrigins: [traceRegex],
      defaultPrivacyLevel: 'mask-user-input',
      enableExperimentalFeatures: ['clickmap', 'feature_flags'],
      beforeSend: datadogRumBeforeSend,
    });

    // enable session recording
    datadogRum.startSessionReplayRecording();
  }
  initialized = true;
}

export const datadogSetUser = (authContext: AuthContextResult) => {
  if (!enableRum) {
    return;
  }

  if (!authContext.authenticated) {
    datadogRum.removeUser();
    return;
  }

  const { user } = authContext;
  const custom = { ...authContext.externalContext };

  // set the authenticated user with DataDog RUM
  datadogRum.setUser({
    id: user?.id?.toString(),
    name: authContext.userFullName,
    email: authContext.userKey,
    ...custom,
  });
};

export const datadogLogError = (error: Error, context?: any) => {
  if (!enableRum) {
    console.error({ error, context });
    return;
  }

  if (!initialized) {
    console.error('DataDog integration not initialized.');
  }

  datadogRum.addError(error, context);
};

// exported for testability
export const resetDataDog = () => {
  initialized = false;
  enableRum = DefaultEnableRum;
};
