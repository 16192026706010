import { Color } from "@material-ui/core";

const red: Color = {
  50: '#FEF2F2',
  100: '#FEE2E2', // error.light
  200: '#FECACA',
  300: '#FCA5A5',
  400: '#F87171',
  500: '#EF4444',
  600: '#DC2626',
  700: '#B91C1C', // error.main
  800: '#991B1B',
  900: '#7F1D1D', // error.dark
  A100: 'magenta',
  A200: 'magenta',
  A400: 'magenta',
  A700: 'magenta'
};

export default red;
