import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { StyleRules } from '@material-ui/core';
import { SelectClassKey, SelectProps } from '@material-ui/core/Select';
import {grey } from '#app/themes/MuiFifthWheelThemeProvider/palette';

export const MuiSelectStyles: Partial<StyleRules<SelectClassKey, {}>> = {
  icon: {
    top: 'auto',
    maxWidth: '1.5em',
    color: grey[500],
    right: '8px',
    // ensure icon has enough spacing away from text
    margin: '0 !important',
    width: '20px'
  },
  select: {
    '&.MuiSelect-select': {
      // override default input padding so caret is properly spaced
      paddingRight: '32px !important'
    },
    '&:focus': {
      backgroundColor: 'inherit'
    }
  }
};

export const MuiSelectProps: SelectProps = {
  IconComponent: ChevronDownIcon,
  variant: 'standard'
};
