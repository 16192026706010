import { muiFifthWheelTheme } from '#app/themes/MuiFifthWheelThemeProvider/theme';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { ReactNode } from 'react';

export interface Props {
  children: ReactNode;
}

export function MuiFifthWheelThemeProvider(props: Props) {
  const { children } = props;

  return (
    <ThemeProvider theme={muiFifthWheelTheme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}

export default MuiFifthWheelThemeProvider;
