export const PagePaths = {
  Home: '/',
  ServerDown: '/server-down',
  NoAccess: '/noaccess',
  AccountPending: '/account/pending',
  AccountPendingApproval: '/account/pending-approval',
  UnknownError: '/unknown-error',
  SignIn: '/sign-in',
  LoadCentral: '/loadcentral',
  LoadCentralMobile: '/mobile/loadcentral',
  ActivateSubscription: '/subscription',
  Fallback: '/fallback',
};
