import { useActor, useInterpret } from '@xstate/react';
import { Suspense, lazy, useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { ErrorBoundary } from '#app/ErrorBoundary';
import { getEnvironmentData } from '#app/api/environment';
import { Auth0ProviderWithHistory } from '#app/components/auth0/Auth0ProviderWithHistory';
import {
  AppContext,
  GlobalEnvironment,
  setGlobalEnvironment,
  useAppContext,
} from '#app/contexts/AppContext/AppContext';
import { initializeFullStory } from '#app/fullstory';
import Loading from '#app/layouts/Loading/Loading';
import { initializeDataDog } from '#app/utils/datadog';
import { logFatalUnexpectedError } from '#app/utils/logging';
import { AppFatalPage } from './AppFatalPage';
import { bootstrapMachine } from './globalMachine';

// exposed for mockability
export const InternalImportAppWithLDProvider = () =>
  import('#app/app/AppWithLDProvider');

const LazyAppWithLDProvider = lazy(() => InternalImportAppWithLDProvider());

export const AppContextProvider: React.FC = (props) => {
  const globalService = useInterpret(bootstrapMachine, {
    actions: {
      environmentReady: () => {
        // Initialize DataDog
        initializeDataDog(GlobalEnvironment);

        // Initialize FullStory
        initializeFullStory(GlobalEnvironment);

        globalService.send('Bootstrapped');
      },
      requestEnvironment: () => {
        const processInitialization = async () => {
          try {
            // bootstrap environment; NOTE test cases: HTTP >= 400
            const environmentData = await getEnvironmentData();

            if (!environmentData.data) {
              throw new Error('/api/environment missing data');
            }

            setGlobalEnvironment(environmentData.data);

            globalService.send({
              type: 'EnvironmentDataSuccess',
              environment: environmentData.data,
            });
          } catch (error: any) {
            logFatalUnexpectedError(
              new Error('Application bootstrapping failed.'),
              { cause: error }
            );
            globalService.send('EnvironmentDataFailed');
          }
        };
        processInitialization();
      },
    },
  });

  return (
    <AppContext.Provider value={{ globalService }}>
      {props.children}
    </AppContext.Provider>
  );
};
/**
 * Performs application bootstrapping.
 * @returns
 */
export const AppBootstrapper: React.VFC = () => {
  const { globalService } = useAppContext();
  const [currentState, send] = useActor(globalService);

  useEffect(() => {
    if (!currentState) {
      return;
    }

    if (currentState.matches('Unknown')) {
      send('Hydrated');
    }
  }, [currentState, send]);

  if (!currentState) {
    return <Loading />;
  }

  if (currentState.matches('Failed')) {
    return <AppFatalPage />;
  }

  if (!currentState.matches('Ready')) {
    return <Loading />;
  }

  return (
    <ErrorBoundary>
      <HelmetProvider>
        <Auth0ProviderWithHistory>
          <Suspense fallback={<Loading />}>
            <LazyAppWithLDProvider />
          </Suspense>
        </Auth0ProviderWithHistory>
      </HelmetProvider>
    </ErrorBoundary>
  );
};
