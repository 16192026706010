import { InputLabelProps, InputProps, StyleRules } from '@material-ui/core';
import { FormHelperTextClassKey } from '@material-ui/core/FormHelperText';
import { InputAdornmentClassKey } from '@material-ui/core/InputAdornment';
import { InputBaseClassKey } from '@material-ui/core/InputBase';
import { InputLabelClassKey } from '@material-ui/core/InputLabel';
import {
  error,
  grey,
  indigo,
  info,
  primary,
  red,
  white
} from '#app/themes/MuiFifthWheelThemeProvider/palette';
import rem from '#app/themes/MuiFifthWheelThemeProvider/rem';
import { typography } from '#app/themes/MuiFifthWheelThemeProvider/typography';

export const MuiInputBase: Partial<StyleRules<InputBaseClassKey, {}>> = {
  root: {
    borderRadius: '4px',
    border: `1px solid ${grey[300]}`,
    backgroundColor: white,
    // need to override MuiAutocomplete padding
    padding: `${rem(8)} ${rem(12)} !important`,
    '&:hover': {
      borderColor: indigo[400],
      '&.Mui-disabled': {
        borderColor: grey[300]
      }
    },
    '&:focus-within': {
      caretColor: primary.main,
      borderColor: indigo[400],
      boxShadow: `0px 0px 0px 4px ${info.dark}`
    },
    '&.Mui-error': {
      caretColor: error.main,
      borderColor: red[300],
      boxShadow: `0px 0px 0px 4px ${error.light}`
    },
    '&.Mui-disabled .MuiInputAdornment-root .MuiTypography-root': {
      color: grey[300]
    },
  },
  input: {
    ...typography.subtitle1,
    height: '24px',
    // necessary to overwrite padding for autocompletes
    padding: '0 !important',
    fontWeight: 400
  },
  multiline: {
    padding: `${rem(8)} ${rem(12)}`
  }
};

export const MuiInputLabelStyles: Partial<StyleRules<InputLabelClassKey, {}>> =
  {
    root: {
      color: grey[500],
      ...typography.subtitle2,
      marginBottom: '4px',
      '&.Mui-error': {
        color: grey[500]
      },

      '&.Mui-error + .MuiAutocomplete-root .MuiAutocomplete-inputRoot': {
        caretColor: error.main,
        borderColor: red[300],
        boxShadow: `0px 0px 0px 4px ${error.light}`
      },
      '&.Mui-focused': {
        color: grey[500]
      },
      '& + .MuiInput-formControl': {
        marginTop: 0
      }
    },
    shrink: {
      transform: 'none'
    },
    animated: {
      transition: 'none'
    },
    formControl: {
      position: 'static',
      transform: 'none'
    }
  };

export const MuiInputLabelProps: InputLabelProps = {
  shrink: true,
  disableAnimation: true
};

export const MuiFormHelperText: Partial<
  StyleRules<FormHelperTextClassKey, {}>
> = {
  root: {
    ...typography.subtitle2,
    marginTop: '4px'
  }
};

export const MuiInputAdornment: Partial<
  StyleRules<InputAdornmentClassKey, {}>
> = {
  root: {
    '& svg': {
      width: '18px'
    }
  },
  positionStart: {
    marginRight: '12px'
  },
  positionEnd: {
    marginLeft: '12px'
  }
};

export const MuiInputProps: InputProps = {
  disableUnderline: true
};
