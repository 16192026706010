import green from './green';
import indigo from './indigo';
import red from './red';
import teal from './teal';
import white from './white';
import yellow from './yellow';

/**
 * All palettes are designed to follow the same coding conventions used by the
 * Material UI framework.
 *
 * Example:
 * hfweb/tnweb/node_modules/@material-ui/core/colors/index.d.ts
 */
export { default as black } from './black';
export { default as green } from './green';
export { default as grey } from './grey';
export { default as indigo } from './indigo';
export { default as red } from './red';
export { default as teal } from './teal';
export { default as white } from './white';
export { default as yellow } from './yellow';

/**
 * Exporting named palettes separately for use in individual components.
 */

export const success = {
  main: green['700'],
  dark: green['800'],
  light: green['500'],
  contrastText: white,
};

export const primary = {
  main: indigo['700'],
  dark: indigo['800'],
  light: indigo['300'],
  contrastText: white,
};

export const secondary = {
  main: '#131c7a', // update to similar header color schema in Mobile app
  dark: teal['800'],
  light: teal['200'],
  contrastText: white,
};

export const info = {
  main: indigo['100'],
  dark: indigo['200'],
  light: indigo['50'],
  contrastText: '#0408E7',
};

export const error = {
  main: red['700'],
  dark: red['900'],
  light: red['100'],
  contrastText: white,
};

export const warning = {
  main: yellow['700'],
  dark: yellow['800'],
  light: yellow['500'],
  contrastText: white,
};
