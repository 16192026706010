import { ErrorInfo } from 'react';
import { datadogLogError } from './datadog';

/**
 * Logs an unexpected error (or message) to the relavant locations.
 * @param error
 * @returns
 */
export const logUnexpectedError = (
  error: string | Error,
  ...optionalParams: any[]
) => {
  // As we don't enable DataDog client console logging, log to console too.

  if (typeof error == 'string') {
    error = new Error(error);
  }

  const errorParams = optionalParams.length > 0 ? optionalParams : undefined;

  console.error('Unexpected error:', error, { ...errorParams });
  datadogLogError(error, errorParams);
};

/**
 * Logs a fatal unexpected error. Application is most likely not initialized.
 * @param error
 * @returns
 */
export const logFatalUnexpectedError = (
  error: Error,
  errorInfo?: ErrorInfo | object
) => {
  console.error('Uncaught fatal error:', error, errorInfo);
};
