/**
 * Fifth Wheel Design System
 *
 * This is the MUI adaptation of the Fifth Wheel Design System.
 *
 * source: https://www.figma.com/file/jRli21rb4wQU3XCsZrNnfx/MUI-5th-Wheel
 */
import { createTheme } from '@material-ui/core';
import { MuiFormHelperText, MuiInputAdornment, MuiInputBase, MuiInputLabelProps, MuiInputLabelStyles, MuiInputProps } from '#app/themes/MuiFifthWheelThemeProvider/Inputs/MuiInputOverrides';
import { MuiSelectProps, MuiSelectStyles } from '#app/themes/MuiFifthWheelThemeProvider/Select/MuiSelectOverrides';
import rem from '#app/themes/MuiFifthWheelThemeProvider/rem';
import { typography } from '#app/themes/MuiFifthWheelThemeProvider/typography';
import { error, grey, info, primary, secondary, success, teal, warning, white } from './palette';


export const muiFifthWheelTheme = createTheme({
  props: {
    // Name of the component ⚛️
    MuiButton: {
      disableElevation: true, // Design system does not use elevation on buttons.
    },
    MuiButtonBase: {
      // The properties to apply
      disableRipple: true, // No more ripple, on the whole application 💣!
      disableTouchRipple: true,
    },
    MuiInputLabel: MuiInputLabelProps,
    MuiSelect: MuiSelectProps,
    MuiInput: MuiInputProps
  },
  /**
   * Everything from this point forward is our new styling.
   *
   * As a general rule, colors and typography should be defined in the `palette` and `typography`
   * sections respectively.
   *
   * Component overrides are often specific to a variant of that component.
   */
  overrides: {
    MuiBadge: {
      badge: {
        borderRadius: 4
      }
    },
    MuiButton: {
      root: {
        textTransform: 'none'
      }
    },
    MuiTab: {
      root: {
        textTransform: 'none'
      }
    },
    MuiStepper: {
      vertical: {}
    },
    MuiStepContent: {
      root: {
        borderLeftColor: grey['500'],
        borderLeftStyle: 'dashed',
        marginTop: 0
      }
    },
    MuiStepConnector: {
      lineVertical: {
        borderLeftColor: grey['500'],
        borderLeftStyle: 'dashed',
        minHeight: 0
      },
      vertical: {
        padding: 0
      }
    },
    MuiStepLabel: {
      active: {
        ...typography.h4
      },
      iconContainer: {
        color: teal['700']
      }
    },
    MuiTableCell: {
      root: {
        borderBottomColor: grey['200']
      },
      stickyHeader: {
        backgroundColor: grey['100']
      }
    },
    MuiTypography: {
      gutterBottom: {
        marginBottom: rem(8)
      }
    },
    MuiInputBase: MuiInputBase,
    MuiInputLabel: MuiInputLabelStyles,
    MuiFormHelperText: MuiFormHelperText,
    MuiInputAdornment: MuiInputAdornment,
    MuiSelect: MuiSelectStyles
  },

  /**
   * General palette settings should be defined here
   *
   * Key order follows the Material-Ui interface at createPalette.d.ts:104 of material-ui library
   */
  palette: {
    primary: primary,
    secondary: secondary,
    info: info,
    error: error,
    warning: warning,
    success: success,
    // type: {},
    // tonalOffset: {},
    // contrastThreshold: {},
    // common: {},
    // grey: {},
    text: {
      /**
       * Material uses this for headings and table cell text
       */
      // primary: '#FF00FF', // enable this if you want to preview everything affected
      primary: grey['900'],

      /**
       * Material uses this for labels and default text
       */
      // secondary: '#00FF00', // enable this if you want to preview everything affected
      secondary: grey['500'],

      /**
       * Material uses this for disabled text (big surprise there!)
       */
      disabled: grey['300']
    },
    // action: {}
    background: {
      paper: white,
      default: white
    },

    // special purpose
    divider: grey['200']

    // does not include:
    // - border
    // - backdrop overlay
    // - filled input background
    // - standard input line
    // - snackbar
    // - rating active
  },

  /**
   * General typography settings should be defined here
   */
  typography: typography
});
