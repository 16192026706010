import React from 'react';
import ReactDOM from 'react-dom';
import { AppErrorBoundary } from './AppErrorBoundary';
import { AppBootstrapper, AppContextProvider } from './app/AppBootstrapper';
import reportWebVitals from './reportWebVitals';
import { MuiFifthWheelThemeProvider } from './themes/MuiFifthWheelThemeProvider';

(async () => {
  ReactDOM.render(
    <React.StrictMode>
      <MuiFifthWheelThemeProvider>
        <AppErrorBoundary>
          <AppContextProvider>
            <AppBootstrapper />
          </AppContextProvider>
        </AppErrorBoundary>
      </MuiFifthWheelThemeProvider>
    </React.StrictMode>,
    document.getElementById('root')
  );
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
