import { History } from 'history';
import Cookies from 'js-cookie';
import { CurrentUser } from '#app/currentUser';
import { PagePaths } from '#app/utils/pagePaths';
import httpClient from './httpClient';

export const KeyCypressSignInTest = 'cypressSignInTest';

const KeyCookieAccessToken = 'access_token';
const KeyCookieAccessTokenExpiry = 'access_token_expiry';

export function isCypress() {
  return !!(window as any).Cypress;
}

export function isCypressSignInTest() {
  return window.localStorage.getItem(KeyCypressSignInTest) === 'true';
}

/**
 * Redirect to the sign-in page.
 * @param history
 */
export function redirectToSignin(history: History) {
  resetAuthToken();
  const currentPath = history.location.pathname;

  // replace history so that the user can't navigate back to the page they were on
  history.replace({
    pathname: PagePaths.SignIn,
    search: `?returnTo=${currentPath}`,
  });
}

// TODO: should this be history navigate?
export function navigateToHomePage() {
  if (window.location.pathname === PagePaths.Home) {
    return;
  }
  window.location.assign(PagePaths.Home);
}

export function navigateToServerDown(history: History) {
  history.push(PagePaths.ServerDown);
}

export function navigateToNotApproved(history: History) {
  history.push(PagePaths.AccountPending);
}

export function navigateToNoAccessPage(history: History) {
  history.push(PagePaths.NoAccess);
}

/**
 * Get the authenticated user's information.
 */
export async function getApiAuthenticatedUser() {
  const response = await httpClient.get<CurrentUser>(`/api/authenticated-user`);
  return response;
}

/**
 * Returns the Authorization Bearer header value.
 * @returns Header value or undefined if no token present.
 */
export const getBearerHeader = () => {
  const token = Cookies.get(KeyCookieAccessToken);

  if (!token) {
    return undefined;
  }
  return `Bearer ${token}`;
};

/**
 * Resets the user token.
 */
export const resetAuthToken = () => {
  Cookies.remove(KeyCookieAccessToken);
  Cookies.remove(KeyCookieAccessTokenExpiry);
};

/**
 * Set's the user token.
 * @param token Token from Auth0.
 */
export const setAuthToken = (token: string) => {
  Cookies.set(KeyCookieAccessToken, token);
};

export const getAuthToken = () => {
  return Cookies.get(KeyCookieAccessToken);
};
