const FigmaFontSize = 16;

/**
 * Convert a value in pixels and return in rems.
 *
 * This function is useful for converting designs from Figma.
 *
 * @param px
 */
function rem(px: number) {
  return px / FigmaFontSize + 'rem';
}

export default rem;
