import { Auth0Provider } from '@auth0/auth0-react';
import React, { useCallback } from 'react';
import { useGlobalEnvironment } from '#app/contexts/AppContext';
import { PagePaths } from '#app/utils/pagePaths';

const PrefixSignInReturnTo = `${PagePaths.SignIn}?returnTo=`;

export const Auth0ProviderWithHistory: React.FC = ({ children }) => {
  const env = useGlobalEnvironment();

  const onRedirectCallback = useCallback((appState: any, _user: any) => {
    let returnTo: string = appState?.returnTo;

    // a little code smelly, but quick and works
    if (returnTo) {
      returnTo = returnTo.replace(PrefixSignInReturnTo, '');
    }

    const redirectTo = returnTo || window.location.pathname;
    // console.log({ redirectTo });
    window.history.replaceState({}, document.title, redirectTo);
  }, []);

  if (!env.AUTH0_DOMAIN || !env.AUTH0_CLIENT_ID) {
    throw new Error('Environment is not valid.');
  }

  return (
    <Auth0Provider
      domain={env.AUTH0_DOMAIN}
      clientId={env.AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: env.API_IDENTIFIER,
      }}
      onRedirectCallback={onRedirectCallback}>
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
