import { Color } from '@material-ui/core';

const indigo: Color = {
  50: '#F5F5FF',  // info.light
  100: '#EDEDFE', // info.main
  200: '#E1E1FE', // info.dark
  300: '#C3C4FE', // primary.light
  400: '#9B9DFD',
  500: '#7375FD',
  600: '#4B4EFC',
  700: '#0408E7', // primary.main
  800: '#0306BA', // primary.dark
  900: '#02058D',
  A100: 'magenta',
  A200: 'magenta',
  A400: 'magenta',
  A700: 'magenta'
};

export default indigo;
