import { assign, createMachine } from 'xstate';
import { isCypress } from '#app/api/auth';
import { AppStateData, Environment } from '#app/contexts/AppContext';

export const bootstrapMachine = createMachine<AppStateData>({
  id: 'WebApplicationState',
  initial: 'Unknown',
  context: {},
  states: {
    Unknown: {
      on: {
        Hydrated: {
          target: 'Boostrapping',
        },
      },
    },
    EnvironmentReady: {
      on: {
        Bootstrapped: {
          target: 'Ready',
          actions: [
            (context) => {
              if (isCypress()) {
                (window as any).appEnvironment = context.environment;
              }
              console.info('Environment bootstrapped', context.environment);
            },
          ],
        },
      },
      entry: ['environmentReady'],
    },
    Ready: {},
    Failed: {},
    Boostrapping: {
      on: {
        EnvironmentDataFailed: {
          target: 'Failed',
        },
        EnvironmentDataSuccess: {
          target: 'EnvironmentReady',
          actions: assign((_context, event) => {
            return { environment: event.environment };
          }),
        },
      },
      entry: ['requestEnvironment'],
    },
  },
  schema: {
    events: {} as
      | { type: 'EnvironmentDataFailed' }
      | { type: 'Hydrated' }
      | { type: 'EnvironmentDataSuccess'; environment: Environment }
      | { type: 'Bootstrapped' },
  },
  // See: https://xstate.js.org/docs/guides/actions.html#api
  predictableActionArguments: true,
  // See: https://xstate.js.org/docs/guides/context.html#action-order
  preserveActionOrder: true,
});
