import { Component, ErrorInfo, ReactNode } from 'react';
import { AppFatalPage } from './app/AppFatalPage';

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

/**
 * Application-level error boundary.
 */
export class AppErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // Must be logged to console at the application level, as the Application
    // probably isn't initialized correctly.
    console.error('Uncaught error:', error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return <AppFatalPage />;
    }

    return <>{this.props.children}</>;
  }
}

export default AppErrorBoundary;
