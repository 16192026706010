import { Box } from '@material-ui/core';
import { Component, ErrorInfo, ReactNode } from 'react';
import { logUnexpectedError } from './utils/logging';

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

export class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    logUnexpectedError(error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return (
        <Box>
          <h1 data-testid="errorboundary">
            The TrueNorth application failed for an unknown reason.{' '}
            <a href="/">Re-launch</a> the application.
          </h1>
        </Box>
      );
    }

    return <>{this.props.children}</>;
  }
}

export default ErrorBoundary;
